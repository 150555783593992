<template>
  <div class="register-container">
    <Spinner v-if="loading" />
    <div class="shapes-container">
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="300"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-out"
        data-aos-duration="2000"
        data-aos-delay="500"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
    </div>
    <section>
      <b-container>
        <div class="block login-marco">
          <div class="image align-self-center login-a">
            <b-img
              :src="require('@/assets/img/background/tbolivar-edeficio.jpg')"
              alt="register-img"
              class="register-img"
            ></b-img>
          </div>
          <div class="content text-center register-background">
            <div class="logo">
              <b-link href="/">
                <b-img
                  :src="require('@/assets/img/logos/logo-01.png')"
                  alt="logo-tributos"
                >
                </b-img>
              </b-link>
            </div>
            <br />
            <div class="title-text">
              <h3>Registre sus Datos</h3>
            </div>
            <br />
            <b-form @submit.prevent="registerUser">
              <b-form-select
                name="tipoPersona"
                v-model="tipoPersona"
                :options="optionsTipoPersona"
                required
                class="form-control"
                @change="setPlaceholder"
              ></b-form-select>
              <b-form-input
                type="text"
                :placeholder="nombrePlaceholder"
                required
                v-model="firstName"
                autocomplete="firstName"
                name="firstName"
                class="register-input"
              ></b-form-input>
              <b-form-input
                v-if="tipoPersona === 'N'"
                type="text"
                placeholder="Apellido"
                v-model="lastName"
                autocomplete="lastName"
                name="lastName"
                class="register-input"
              ></b-form-input>
              <b-form-input
                type="text"
                :placeholder="dniPlaceholder"
                required
                v-model="dni"
                autocomplete="dni"
                name="dni"
                class="register-input"
                aria-describedby="dni-help"
              ></b-form-input>
              <b-form-text id="dni-help" style="text-align: left">
                {{ dniHelp }}
              </b-form-text>
              <b-form-input
                type="email"
                placeholder="Correo"
                required
                v-model="email"
                autocomplete="email"
                name="email"
                class="register-input"
              ></b-form-input>
              <b-form-input
                type="password"
                placeholder="Clave"
                required
                v-model="password"
                name="password"
                class="register-input"
              ></b-form-input>
              <b-form-input
                type="password"
                placeholder="Confirme Clave"
                required
                v-model="passwordConfirm"
                name="password-confirm"
                class="register-input"
              ></b-form-input>
              <br />
              <vue-hcaptcha
                sitekey="5d892cfe-fb01-4d7e-bf42-c664bd21411a"
                @verify="verify"
                language="es"
              ></vue-hcaptcha>
              <br />
              <b-button
                type="submit"
                block
                variant="primary"
                class="register-input"
              >
                Registrar
              </b-button>
            </b-form>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import Spinner from "@/components/Otros/Spinner.vue";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "RegisterView",
  components: {
    Spinner,
    VueHcaptcha,
  },
  data() {
    return {
      loading: false,
      firstName: null,
      lastName: null,
      dni: null,
      email: null,
      password: null,
      passwordConfirm: null,
      tipoPersona: null,
      dniPlaceholder: "Cédula/Rif",
      nombrePlaceholder: "Nombre/Razon Social",
      error: null,
      captcha: false,
      dniHelp: "Ejemplo:",
      optionsTipoPersona: [
        { value: null, text: "Tipo de Persona" },
        { value: "V", text: "Natural" },
        { value: "J", text: "Jurídica" },
        { value: "G", text: "Gubernamental" },
        { value: "I", text: "Institucion Educativa" },
      ],
    };
  },
  methods: {
    async registerUser() {
      this.loading = true;
      this.error = null;
      const payload = {
        firstname: this.firstName,
        lastname: this.lastName,
        dni: this.dni,
        sexo: "masculino",
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirm,
        tipo_persona: this.tipoPersona,
      };
      try {
        if (this.captcha) {
          await AuthService.registerUser(payload);
          this.loading = false;
          this.$toast.success(
            "Usted ha sido registrado correctamente, por favor guarde su información de usuario en un lugar seguro y fácil de recordar.",
            {
              timeout: 15000,
            }
          );
          this.$router.push("/login");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error(getError(error), { timeout: 6000 });
      }
    },
    setPlaceholder() {
      if (this.tipoPersona === "V") {
        this.dniPlaceholder = "Cédula";
        this.nombrePlaceholder = "Nombre";
      } else if (["J", "G", "I"].includes(this.tipoPersona)) {
        this.dniPlaceholder = "Rif";
        this.nombrePlaceholder = "Razon Social";
      } else {
        this.firstName = null;
        this.lastName = null;
        this.dni = null;
        this.email = null;
        this.password = null;
        this.passwordConfirm = null;
        this.dniPlaceholder = "Cédula/Rif";
        this.nombrePlaceholder = "Nombre/Razon Social";
        this.dniHelp = "Ejemplo:";
      }
    },
    verify() {
      this.captcha = true;
    },
  },
};
</script>
